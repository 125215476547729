<template>
    <div class="clip_project">
        <div class="clip_top">
            <div class="title">工程列表</div>
            <el-button type="primary" size="mini" @click="creatPoject"
                >创建工程</el-button
            >
        </div>
        <div class="clip_main">
            <el-row :gutter="20">
                <el-col
                    :xl="3"
                    :lg="4"
                    :md="6"
                    :sm="8"
                    :xs="12"
                    v-for="(item, index) in poject_list"
                    :key="index"
                >
                    <div class="card_box" @click="openClip(item.ProjectId)">
                        <div class="img_box">
                            <img
                                width="100%"
                                height="100%"
                                src="@/assets/images/work/defalBg.png"
                                alt=""
                            />
                        </div>
                        <div class="info-box">
                            <ul>
                                <li>{{ item.Title }}</li>
                                <!-- <li>2023-10-10 13:00:00</li> -->
                            </ul>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="clip_page">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="where.pageNo"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="where.PageSize"
                layout="sizes, prev, pager, next"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getClip, createClip } from "@/api/ali";
export default {
    name: "clipList",

    data() {
        return {
            poject_list: [],
            total: 0,
            where: {
                PageNo: 1,
                PageSize: 10,
            },
            add_txt: "",
        };
    },

    mounted() {},
    created() {
        this.getPoject();
    },
    methods: {
        async getPoject(data) {
            let where = data ? data : this.where;
            let res = await getClip(where);

            console.log(res);
            this.total = res.TotalCount;
            this.poject_list = res.ProjectList;
        },
        handleCurrentChange(curren) {
            this.where.pageNo = curren;
            this.getPoject(this.where);
        },
        handleSizeChange(size) {
            this.where.PageSize = size;
            this.getPoject(this.where);
        },
        creatPoject() {
            this.$prompt("工程称号", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(({ value }) => {
                createClip({ Title: value }).then((res) => {
                    if (res.Project.Status == 1) {
                        let dataJson = {
                            Title: value,
                            ProjectId: res.Project.ProjectId,
                        };
                        this.poject_list.unshift(dataJson);
                        this.$message.success("创建工程成功");
                    } else {
                        this.$message.error("创建工程成功");
                    }
                });
            });
        },
        openClip(pojectId) {
            this.$router.push(`/make/edit/${pojectId}`);
        },
    },
};
</script>

<style lang="less" scoped>
.clip_project {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #171a1e;
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
    .clip_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .clip_main {
        flex: 1;
        height: 0;
        overflow: hidden auto;
        padding: 20px 0;
        box-sizing: border-box;
    }
}
.card_box {
    border: 1px solid #eaeaeb;
    border-radius: 4px;
    margin-bottom: 20px;
    .info-box {
        padding: 12px 10px;
        font-size: 12px;
        li + li {
            margin-top: 4px;
        }
    }
    .img-box {
        line-height: 0;
    }
}
</style>