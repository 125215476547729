import request from '@/utils/request'

// 剪辑

// 获取工程列表
export function getClip(data) {
    return request({
        url: '/api/app/aliYun/apiPostTest',
        data: {
            Action: "SearchEditingProject",
            ...data
        },
        method: 'POST',
    })
}
// 获取工程列表
export function createClip(data) {
    return request({
        url: '/api/app/aliYun/apiPostTest',
        data: {
            Action: "CreateEditingProject",
            ...data
        },
        method: 'POST',
    })
}